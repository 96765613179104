// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-code-invite-js": () => import("./../../../src/pages/code-invite.js" /* webpackChunkName: "component---src-pages-code-invite-js" */),
  "component---src-pages-cpf-serasa-js": () => import("./../../../src/pages/cpfSerasa.js" /* webpackChunkName: "component---src-pages-cpf-serasa-js" */),
  "component---src-pages-deal-invite-js": () => import("./../../../src/pages/deal-invite.js" /* webpackChunkName: "component---src-pages-deal-invite-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-finish-invite-js": () => import("./../../../src/pages/finish-invite.js" /* webpackChunkName: "component---src-pages-finish-invite-js" */),
  "component---src-pages-form-js": () => import("./../../../src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-old-home-js": () => import("./../../../src/pages/old-home.js" /* webpackChunkName: "component---src-pages-old-home-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-produtos-de-beleza-js": () => import("./../../../src/pages/produtos-de-beleza.js" /* webpackChunkName: "component---src-pages-produtos-de-beleza-js" */),
  "component---src-pages-serasa-analytic-js": () => import("./../../../src/pages/serasaAnalytic.js" /* webpackChunkName: "component---src-pages-serasa-analytic-js" */),
  "component---src-pages-signin-number-js": () => import("./../../../src/pages/signin-number.js" /* webpackChunkName: "component---src-pages-signin-number-js" */),
  "component---src-pages-signin-sms-js": () => import("./../../../src/pages/signin-sms.js" /* webpackChunkName: "component---src-pages-signin-sms-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-signup-participant-js": () => import("./../../../src/pages/signup-participant.js" /* webpackChunkName: "component---src-pages-signup-participant-js" */),
  "component---src-pages-signup-test-1-js": () => import("./../../../src/pages/signup-test1.js" /* webpackChunkName: "component---src-pages-signup-test-1-js" */),
  "component---src-pages-success-invite-js": () => import("./../../../src/pages/success-invite.js" /* webpackChunkName: "component---src-pages-success-invite-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-vendedores-alimento-js": () => import("./../../../src/pages/vendedores-alimento.js" /* webpackChunkName: "component---src-pages-vendedores-alimento-js" */),
  "component---src-pages-vendedores-roupa-js": () => import("./../../../src/pages/vendedores-roupa.js" /* webpackChunkName: "component---src-pages-vendedores-roupa-js" */),
  "component---src-pages-video-tutorial-js": () => import("./../../../src/pages/video-tutorial.js" /* webpackChunkName: "component---src-pages-video-tutorial-js" */)
}

